import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { useSignOut, useAuthUser } from 'react-auth-kit';
import {
  IconButton,
  Box,
  Flex,
  HStack,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { FiMenu } from 'react-icons/fi';
import { FaRegUserCircle } from 'react-icons/fa';
import SidebarContent from './SidebarContent';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { useRequest } from './utils/api';
import { getUserVerticals } from './utils';
import { VerticalContext } from './commons/contexts';
import VerticalSelector from './commons/VerticalSelector';
import './index.css';

function SecureLayout() {
  const authUser = useAuthUser();
  const verticals = getUserVerticals(authUser());
  console.log(verticals);
  const [cookies, setCookie] = useCookies(['vertical']);
  let defaultVertical = verticals?.[0] || 'WELLNESS';
  if (cookies.vertical && verticals?.includes(cookies.vertical)) {
    defaultVertical = cookies.vertical;
  }
  const [vertical, setVerticalState] = useState(defaultVertical);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const setVertical = selected => {
    setCookie('vertical', selected);
    setVerticalState(selected);
  };

  return (
    <VerticalContext.Provider value={{ vertical, verticals, setVertical }}>
      <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: 'none', md: 'block' }}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full"
        >
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        <MobileNav onOpen={onOpen} />
        <Box ml={{ base: 0, md: 60 }} p="6">
          <Outlet />
        </Box>
      </Box>
    </VerticalContext.Provider>
  );
}

const MobileNav = ({ onOpen, ...rest }) => {
  const signOut = useSignOut();
  const navigate = useNavigate();
  const { request } = useRequest('logout', 'post');
  const logOut = () => {
    if (signOut()) request();
  };
  const changePassword = () => {
    navigate('/change-password');
  };
  const editProfile = () => {
    navigate('/profile');
  };

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'space-between' }}
      {...rest}
    >
      <Box display={{ base: 'none', md: 'flex' }}>
        <VerticalSelector />
      </Box>

      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Box display={{ base: 'flex', md: 'none' }}>
        <RouterLink to={'/'}>
          <img
            src="./logo_full.png"
            alt="Urban Thai"
            style={{ height: '60px' }}
          />
        </RouterLink>
      </Box>

      <HStack spacing={{ base: '0', md: '2' }}>
        <ColorModeSwitcher />
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              as={IconButton}
              p={3}
              rounded={'full'}
              variant={'link'}
              cursor={'pointer'}
              minW={0}
            >
              <FaRegUserCircle className="tw-text-xl"></FaRegUserCircle>
            </MenuButton>
            <MenuList>
              <MenuItem onClick={editProfile}>Profile</MenuItem>
              <MenuItem onClick={changePassword}>Change Password</MenuItem>
              <MenuItem onClick={logOut}>Logout</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

export default SecureLayout;
