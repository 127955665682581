import React, { useState } from 'react';
import {
  Stack,
  Text,
  Button,
  Box,
  VStack,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  FormErrorMessage,
  useColorModeValue,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { get } from 'lodash';
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import { useRequest } from '../utils/api';

const formatError = error => {
  if (error.message) {
    return error.message;
  }
  if (error instanceof Array) {
    return error.concat('');
  }
};

function ChangePassword() {
  const [updateError, setUpdateError] = useState(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  const colorModeValue = useColorModeValue('white', 'gray.700');
  const { request: updatePassword } = useRequest('change-password', 'post');

  const {
    handleSubmit,
    register,
    formState: { errors: formErrors, isSubmitting },
  } = useForm();

  const mustChangePassword = state?.mustChange;

  const onSubmit = async values => {
    setUpdateError(null);
    const { error } = await updatePassword(values);
    if (error) {
      setUpdateError(error);
      return;
    }
    navigate('/home');
  };

  const errors = {
    ...formErrors,
    ...get(updateError, 'response.data.errors', {}),
  };

  return (
    <div>
      <div className="tw-flex tw-justify-end">
        <ColorModeSwitcher></ColorModeSwitcher>
      </div>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <img
            src="./logo_full.png"
            alt="Urban Thai"
            style={{ height: '120px' }}
          />
          <Text fontSize={'lg'} color={'gray.600'}>
            Change Password
          </Text>
        </Stack>
        <Box rounded={'lg'} bg={colorModeValue} boxShadow={'lg'} p={8}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {updateError && (
              <Text color="tomato" align={'center'} className="tw-mb-2">
                {updateError?.message}
              </Text>
            )}
            <VStack spacing={5}>
              <FormControl isRequired isInvalid={errors.password}>
                <FormLabel>Password</FormLabel>
                <Input
                  type="password"
                  {...register('password', { required: 'This is required' })}
                />
                <FormHelperText>
                  The new password must contain at least one lowercase letter,
                  one uppercase letter and one digit.
                </FormHelperText>
                {!!errors.password && (
                  <FormErrorMessage>
                    {formatError(errors.password)}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isRequired isInvalid={errors.password_confirmation}>
                <FormLabel>Password Confirmation</FormLabel>
                <Input
                  type="password"
                  {...register('password_confirmation', {
                    required: 'This is required',
                  })}
                />
                {!!errors.password_confirmation && (
                  <FormErrorMessage>
                    {formatError(errors.password_confirmation)}
                  </FormErrorMessage>
                )}
              </FormControl>
              <Box textAlign={'right'} width="full">
                {!mustChangePassword && (
                  <Button
                    type="button"
                    colorScheme={'blue'}
                    marginRight={4}
                    variant="outline"
                    onClick={() => navigate('/home')}
                  >
                    Back
                  </Button>
                )}
                <Button
                  isLoading={isSubmitting}
                  type="submit"
                  colorScheme={'blue'}
                >
                  Confirm
                </Button>
              </Box>
            </VStack>
          </form>
        </Box>
      </Stack>
    </div>
  );
}

export default ChangePassword;
